<template>
  <form class="form-inline">
    <table :id="id" class="display table table-condensed vacationDataTable" style="width:100% !important; border-collapse:collapse;">
      <thead>
        <tr>
          <th width="2%"></th>
          <th>employee id</th>
          <th>vacation year</th>
          <th>vacation days due</th>
          <th>travel days due</th>
          <th>carried over days</th>
          <th>unscheduled vacation days</th>
          <th>unscheduled r&amp;r days</th>
          <th>unscheduled carried over days</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(vm, index) in data" :key="vm.id">
          <td>{{ index + 1 }}</td>
          <td>{{ vm.employeeId }}</td>
          <td>{{ vm.vacationYear }}</td>
          <td>{{ vm.vacationDaysDue }}</td>
          <td>{{ vm.travelDays }}</td>
          <td>{{ vm.carriedOverDays }}</td>
          <td>{{ vm.unscheduledVacationDays }}</td>
          <td>{{ vm.unscheduledRnRDays }}</td>
          <td>{{ vm.unscheduledCarriedOverDays }}</td>
        </tr>
      </tbody>
    </table>
  </form>
</template>
<style lang="scss">

  form div {
    width: 100% !important
  }

  [class^="col-"] {
    margin-right: 0 !important;
  }

  .dataTables_length label {
    justify-content: flex-start !important;
  }

  .dataTables_filter label, .pagination {
    justify-content: flex-end !important;
  }

  .dataTables_info {
    text-align: left
  }

  td {
    /*vertical-align: middle;*/
  }

  table tbody tr:nth-child(odd) td {
    background: #fff;
  }

  tr.shown + tr, .detail-table tbody tr:nth-child(odd) td {
    /*background: rgba(251, 241, 241, 0.5) !important*/
    background: rgba(128, 128, 128, 0.08) !important
  }

  /*.detail-table tbody tr:nth-child(odd) td {
      background: rgb(128, 128, 128) !important
    }*/

  /*td.details-control {
    background: url('../../assets/details_open.png') no-repeat center !important;
    cursor: pointer;
  }

  tr.shown td.details-control {
    background: url('../../assets/details_close.png') no-repeat center !important;
  }*/

  .table td {
    border: none
  }

  div.dt-buttons {
    width: 50px !important;
    float: right !important;
    margin-top: -40px;
    margin-right: 50px;
  }

  /*.popover {
    width: 600px !important;
  }

  .popover-body {
    width: 600px !important;
  }*/

  .dt-buttons{
      display: contents !important;
  }
</style>
<style scoped lang="scss">
  .actionicons {
    /*position: relative;
    left: 20px;*/
    visibility: hidden;
  }

  tr:hover .actionicons {
    visibility: visible;
  }

  button {
    cursor: pointer
  }

  .visitorsTable tr {
    cursor: pointer
  }

  table tbody tr.hover td:not(.details-control) {
    background: rgba(76, 175, 80, 0.05) !important
    /*background: rgba(128, 128, 128, 0.05) !important*/
  }

  /*table tbody tr.hover td.details-control {
    background: url('../../assets/details_open.png') no-repeat center rgba(76, 175, 80, 0.05) !important;
  }

  table tbody tr.shown.hover td.details-control {
    background: url('../../assets/details_close.png') no-repeat center rgba(76, 175, 80, 0.05) !important;
  }*/

  .dropdown-toggle::after {
    content: '' !important;
  }

  .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }

  .site {
    width: 25px;
    margin-right: 5px;
    font-family: 'Segoe UI Symbol'
  }


  /*Card Layout*/
  table.cards {
    background-color: transparent;
    justify-content: center
  }

  /*--[  This does the job of making the table rows appear as cards ]----------------*/



  /*---[ The remaining is just more dressing to fit my preferances ]-----------------*/
  .table {
    background-color: #fff;
  }

    .table .glyphicon {
      font-size: 20px;
    }

  .cards .glyphicon {
    font-size: 75px;
  }

  .cards tbody td:nth-child(1) {
    display: flex;
    justify-content: center;
  }

  .btn-primary:after, .btn-primary:after {
    content: '';
    display: inline-block;
    font-size: 16px;
    padding: 0 0 0 5px;
  }
</style>
<script>

  export default {
    name: 'VacationDataTable',
    props: ['id', 'data', 'tableProps'],
    data: () => ({
      tableObject: null,
    }),
    methods: {
      refresh() {
        const self = this;
        if (self.tableObject) {
          self.tableObject.destroy();
        }
        self.tableObject = $(`#${self.id}`).DataTable({
          "scrollX": true,
          searching: false,
          paging: true,
          lengthMenu: [9, 12, 15, 18, 20],
          info: false,
          order: (self.tableProps && self.tableProps.order ? self.tableProps.order : []),
          pageLength: (self.tableProps && self.tableProps.page ? self.tableProps.page.length : 9),
          displayStart: (self.tableProps && self.tableProps.page ? self.tableProps.page.length * self.tableProps.page.page : 0),
          dom: 'lBfrtip',
          buttons: [
            'copy', 'csv', 'excel', 'pdf', 'print'
          ]
        });

        $(`#${self.id}`).on('page.dt', function () {
          self.tableProps.page = self.tableObject.page.info();
        });

        $(`#${self.id}`).on('order.dt', function () {
          self.tableProps.order = self.tableObject.order();
        });
      }
    },
    beforeDestroy() {
      if (this.tableObject) {
        this.$nextTick(() => {
          this.tableObject.destroy();
        });
      }
    }
  }
</script>

