<template>
  <div id="leaveRequestSearchView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>search leave requests</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="row mb-1">
          <div class="col-md-4 mr-0">
            <div class="row">
              <label class="col-md-4 mr-0 text-left small text-secondary">year</label>
              <select class="col-md-5 mr-0 form-control" v-model="searchParams.vacationYear">
                <option value="">All</option>
                <option v-for="year in vacationYears" :value="year">{{ year }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4 mr-0">
            <div class="row">
              <label class="col-md-4 mr-0 text-left small text-secondary">leave type</label>
              <select class="col-md-5 mr-0 form-control" v-model="searchParams.leaveType">
                <option value="">All</option>
                <option v-for="requestType in leaveRequests" :value="requestType.searchValue">{{ requestType.description }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4 mr-0">
            <div class="row">
              <label class="col-md-4 mr-0 text-left small text-secondary">status</label>
              <select class="col-md-5 mr-0 form-control" v-model="searchParams.status">
                <option value="">All</option>
                <option v-for="requestStatus in requestStatuses" :value="requestStatus.value">{{ requestStatus.description }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md-4 mr-0">
            <div class="row">
              <div class="col-md-12 mr-0">
                <label class="col-md-4 mr-0 text-left small text-secondary">employee name</label>
                <span class="col-md-5 mr-0 font-weight-bold text-left">{{ searchParams.employeeName }}</span>
                <!--<employee-autocomplete label="employee name" id="emp_search" :sData="searchParams.employeeName" v-bind:isShown="true" :isDisabled="true" />-->
              </div>
            </div>
          </div>
          <div class="col-md-4 mr-0">
            <div class="row">
              <div class="col-md-12 mr-0">
                <input type="checkbox" id="includeDirectReports" name="includeDirectReports" v-model="searchParams.includeDirectReports" />
                <label for="includeDirectReports">&nbsp;&nbsp;include direct reports</label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row text-center">
          <div class="col-sm-12 mr-0">
            <button type="submit" class="button bttn btn-primary" @click.prevent="search">Search</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="result && result.length">
      <div class="col">
        <div>
          <LeaveRequestTable ref="leaveRequestTable" :requests="result" id="leaveRequestTable" :key="'leaveRequestTable-'+ currentTableKeyIndex" :tableProps="tableProps"></LeaveRequestTable>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  input:invalid {
    border: 1px solid red !important;
  }
</style>

<script>
  import store from '@/store'

  import { toStatusDescription } from '@/filters/enum'

  import Enums from '@/utils/enums'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import { EMPLOYEE_SELECTED_EVENT } from '@/utils/constants'


  import { reportService } from "@/services"

  import AppAlert from '@/components/ui/AppAlert'
  import AppModal from '@/components/ui/AppModal'
  import EmployeeAutocomplete from '@/components/ui/EmployeeAutocomplete'

  import EmployeeSearchMixin from '@/mixins/employeeSearch'
  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'

  import LeaveRequestTable from '@/components/reports/LeaveRequestTable'

  export default {
    name: 'LeaveRequestSearchView',
    components: {
      EmployeeAutocomplete,
      AppAlert,
      AppModal,
      LeaveRequestTable
    },
    mixins: [EmployeeSearchMixin, AlertMixin, ErrorMixin],
    data: () => ({
      searchParams: {
        vacationYear: '',
        employeeId: '',
        employeeName: '',
        leaveType: '',
        status: '',
        includeDirectReports: false
      },
      result: [],
      currentTableKeyIndex: 1,
      tableProps: {
        order: null,
        page: null,
        layout: 'list'
      },
      Enums: Enums
    }),
    computed: {
      leaveRequests() {
        let requestTypes = Object.keys(Enums.RequestType)
        requestTypes = requestTypes.map(requestType => Enums.RequestType[requestType])
        return requestTypes.filter(r => r.type === 'leaveRequest')
      },
      requestStatuses() {
        let requestStatuses = Object.keys(Enums.RequestStatus)
        requestStatuses = requestStatuses.map(requestStatus => Enums.RequestStatus[requestStatus])
        return requestStatuses
      },
      vacationYears() {
        const year = new Date().getFullYear()
        return Array.from({ length: year - 2002 }, (value, index) => 2003 + index)
      }
    },
    watch: {
    },
    methods: {
      setEmployee(employee) {
        this.searchParams.employeeId = employee.employeeId
        this.searchParams.employeeName = employee.fullName
      },
      resetData() {
        //this.searchParams.employeeId = ''
        //this.searchParams.employeeName = ''
      },
      resetTableData() {
        this.result.splice(0, this.result.length)
      },
      search() {
        const self = this
        self.resetTableData();
        self.closeMessage();
        if (!self.searchParams.vacationYear && !self.searchParams.employeeId && !self.searchParams.leaveType) {
          self.showErrorMessage("Kindly select a searcb criteria");
          return;
        }
        eventBus.$emit(Events.LongOperationStarted, '')
        reportService.searchLeaveRequests(self.searchParams).then((result) => {
          if (result && result.length) {
            self.result = [...result]
            self.$nextTick(() => {
              self.$refs.leaveRequestTable.refresh();
            });
          }
          else {
            self.showErrorMessage("There are no leave requests that match the search criteria");
          }
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })

      }
    },
    filters: {
      toStatusDescription
    },
    mounted() {
      var today = new Date()
      this.searchParams.vacationYear = today.getFullYear()
      //this.searchParams.status = this.Enums.AllowancePaymentStatus.Requested.value
      this.searchParams.employeeId = store.state.userModule.currentUser.employeeId
      this.searchParams.employeeName = store.state.userModule.currentUser.fullName
    //  eventBus.$on(EMPLOYEE_SELECTED_EVENT, this.setEmployee)
    },
    beforeRouteUpdate(to, from, next) {
      this.tableProps.order = null;
      this.tableProps.page = null;
      this.tableProps.layout = null;
      this.closeMessage();
      next();
    },
    beforeDestroy() {
    //  eventBus.$off(EMPLOYEE_SELECTED_EVENT)
    }
  }
</script>
